import {CTWorkOrderRow} from "./ct-work-order.row";

export class CtWorkOrderRowOffer extends CTWorkOrderRow {

  Offer: any | null = null;

  public static override create = (model?: CTWorkOrderRow) => <CtWorkOrderRowOffer>CTWorkOrderRow.create(model);

  setOffer(value: any | null) {

    this.Offer = value;
    return this;

  }

}
