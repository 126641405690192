import {Component, Input} from "@angular/core";
import {Handling} from "../../../../../../_core/classes/handling";
import {HandlingOriginEnum} from "../../../../../../_core/enum/handling-origin.enum";


@Component({
  selector: "app-module-item-order",
  templateUrl: "./module-item-order.component.html",
  styleUrls: ["./module-item-order.component.scss"]
})
export class ModuleItemOrderComponent {

  @Input() handlings: Handling[] | null = null;

  getSourceFrom(handling: Handling): string | null | undefined {

    return handling.HandlingOrigin === HandlingOriginEnum.Location ? handling.LocationFrom?.Name : handling.SupplierFrom?.ReferenceName;

  }

  getSourceIcon(handling: Handling): string | null | undefined {

    return handling.HandlingOrigin === HandlingOriginEnum.Location ? 'swap_horiz' : 'local_shipping';

  }

}
