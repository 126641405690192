
<mat-card  *ngIf="this.leadOid">

  <mat-card-content style="text-align: right;">

    <ct-button [configuration]="submitButton"></ct-button>

  </mat-card-content>

</mat-card>
