<mat-card *ngIf="information.length > 0">

  <mat-card-header>

    <mat-card-title> Informazioni cliente</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <ng-container *ngFor="let item of information">
    
      <span *ngIf="item.Content"><strong>{{ getContactTitle(item.Type) }}:</strong>  {{ item.Content }} <br></span>

    </ng-container>
    
  </mat-card-content>

</mat-card>