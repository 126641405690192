import {Component, OnInit} from "@angular/core";
import {
  CtBinaryOperator,
  CtDatatableActionConfiguration,
  CtDatatableConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {ActivatedRoute, Router} from "@angular/router";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {CTMGeneralService} from "../../../_core/lib/general.service";
import {WorkOrderRouteData} from "../../../_core/route-data/work-order.route-data";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";
import {CTMWorkOrderViewType} from "../../../_core/enum/work-order-type";
import {WorkOrderService} from "../work-order.service";

@Component({
  selector: "app-work-order-list",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class WorkOrderListComponent implements OnInit {

  configuration: CtModelConfiguration<WorkOrderListComponent> | null = null;
  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION();

  depositRedirectCTA: CtDatatableActionConfiguration = new CtDatatableActionConfiguration()
    .setColor("primary")
    .setIcon("warehouse");

  CTMWorkOrderType: CTMWorkOrderViewType = CTMWorkOrderViewType.Standard;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private general: CTMGeneralService,
    private managerService: CtManagerService,
    private workOrderService: WorkOrderService) {
  }

  ngOnInit() {

    this.setup();

  }

  get isQuotation() {

    return this.CTMWorkOrderType === CTMWorkOrderViewType.Quotation;

  }

  async setup() {

    this.CTMWorkOrderType = await this.workOrderService.getRouteWorkOrderType(this.route);

    const controllerRoute = this.workOrderService.getRoutePathController(this.CTMWorkOrderType);

    const config = CtModelConfiguration
      .create<WorkOrderListComponent>()
      .setRouteData(WorkOrderRouteData())
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setOnCreate(() => this.general.navigateOnCTModelRouter(controllerRoute, "create"))
      .setOnEdit(value => this.general.navigateOnCTModelRouter(controllerRoute, "edit", {
        value,
        configuration: this.configuration
      }))
      .setFieldsCustomTemplates([
        {
          prop: 'Quotation',
          visible: false
        },
        {
          prop: 'QuotationAcceptAt',
          visible: this.isQuotation
        },
        {
          prop: 'StartAt',
          visible: !this.isQuotation
        },
        {
          prop: 'ExpiredAt',
          visible: !this.isQuotation
        },
        {
          prop: 'TotalAmount',
          visible: this.isQuotation
        }
      ])

    const userHasWarehouseManagementEnabled = await this.managerService.hasEnabled.warehouse() ?? false;

    if (userHasWarehouseManagementEnabled) {

      this.datatableConfiguration
        .setCustomDatatableAction(
          this.depositRedirectCTA,
          (event => {
            this.router.navigate(["location", "stores"], {
              queryParams: {
                WorkOrder: event.Oid,
                WorkOrderName: event.Name
              }
            })
          }));

    }

    let constantFilters;

    switch (this.CTMWorkOrderType) {

      case CTMWorkOrderViewType.Quotation:
        constantFilters = new Array<CTModelDatatableFilter>(
          this.getCTModelDatatableFilter<Array<any>>('Quotation = True and QuotationAcceptAt is null', [], CtBinaryOperator.AndOperator))
        break;

      case CTMWorkOrderViewType.Standard:
      default:
        constantFilters = new Array<CTModelDatatableFilter>(
          this.getCTModelDatatableFilter<Array<any>>('(Quotation = True and QuotationAcceptAt is not null) or Quotation = False', [], CtBinaryOperator.AndOperator));
        break;

    }

    constantFilters
      .forEach(filter => config.filterValues.addConstantFilter(filter))

    this.configuration = config;

    this.route
      .data
      .subscribe(data => {

        if (this.isQuotation) data['title'] = "CT_MENU.quotations";

      })

  }

  private getCTModelDatatableFilter = <T>(field: string, value: T, operator: CtBinaryOperator) => CTModelDatatableFilter
    .create()
    .setField(field)
    .setValue(value).setOperatorType(operator)

}
