import {CtModelRouteData} from "@ctsolution/ct-framework";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";

export const WorkOrderRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("workorder");

export const createFromLead : () => DataRequest = () => new DataRequest()
  .setController([WorkOrderRouteData().controller!])
  .setAction('CreateFromLead')
  .setMethod(MethodEnum.POST);
