import {CTBase} from "@ctsolution/ct-base";
import {CTWorkOrderInformation} from "./ct-work-order.information";
import {CTWorkOrderRowUnionType} from "./ct-work-order.row-union";

export class CTWorkOrder<C> extends CTBase<CTWorkOrder<C>> {

  Oid?: number;
  Code: string | null = null;
  ExtraString: string | null = null;
  Name: string | null = null;
  ExternalCode: string | null = null;
  StartAt: Date | null = null;
  ExpiredAt: Date | null = null;
  Company: { Oid: number } | any | null = null;
  State: { Oid: number } | any | null = null;
  Contact: { Oid: number } | any | null = null;
  Address: string | null = null;
  Description: string | null = null;
  Informations: Array<CTWorkOrderInformation<C>> = [];
  Rows: Array<CTWorkOrderRowUnionType> = [];
  ExtraDataJSON: any | null = null;
  Quotation: boolean = false;
  QuotationAcceptAt: Date | null = null;
  Amount: number | null = null;

  private constructor();
  private constructor(model?: CTWorkOrder<C>)
  private constructor(model?: CTWorkOrder<C>) {

    super();

    if (model) this.getClass(model);

  }

  public static create = <C>(model?: CTWorkOrder<C>) => new CTWorkOrder(model);

  setOid(value?: number) {

    this.Oid = value;
    return this;

  }

  setCode(value: string | null) {

    this.Code = value;
    return this;

  }

  setExtraString(value: string | null) {

    this.ExtraString = value;
    return this;

  }

  setExternalCode(value: string | null) {

    this.ExternalCode = value;
    return this;

  }

  setName(value: string | null) {

    this.Name = value;
    return this;

  }

  setAmount(value: number | null) {

    this.Amount = value;
    return this;

  }

  setAddress(value: string | null) {

    this.Address = value;
    return this;

  }

  setDescription(value: string | null) {

    this.Description = value;
    return this;

  }

  setStartAt(value: Date | null) {

    this.StartAt = value;
    return this;

  }

  setExpiredAt(value: Date | null) {

    this.ExpiredAt = value;
    return this;

  }

  setQuotationAcceptAt(value: Date | null) {

    this.QuotationAcceptAt = value;
    return this;

  }

  setQuotation(value: boolean) {

    this.Quotation = value;
    return this;

  }

  setCompanyOid(value: number) {

    this.Company = {Oid: value};
    return this;

  }

  setContactOid(value: number) {

    this.Contact = {Oid: value};
    return this;

  }

  setStateOid(value: number) {

    this.State = {Oid: value};
    return this;

  }

  setExtraDataJSON(value: any | null) {

    this.ExtraDataJSON = value;
    return this;

  }

}
