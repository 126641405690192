import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {CtButtonConfiguration, MAT_RAISED_WARN} from "@ctsolution/ct-framework";

@Component({
  selector: 'app-work-order-errors-dialog',
  template: `
    <h1 mat-dialog-title> {{ 'Attenzione' | translate }} </h1>

    <div mat-dialog-content>
      <p> {{ 'Si sono verificati alcuni errori durante il processo di import:' | translate }}</p>
      <ul>
        <li *ngFor="let error of errors"> {{ error }}</li>
      </ul>
    </div>

    <div mat-dialog-actions align="end">

      <ct-button [configuration]="cancelButton"></ct-button>

    </div>`
})
export class WorkOrderErrorsDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public errors: string[], private dialogRef: MatLegacyDialogRef<any>) {
  }

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Chiudi")
    .setAction(() => this.dialogRef.close())
    .setMatherialOptions(MAT_RAISED_WARN);

}
