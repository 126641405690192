import {Component} from '@angular/core';
import {
  CtButtonConfiguration,
  CtControlConfiguration,
  CtControlTypes,
  CtThemeTypes,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CTMGeneralService} from "../../../../_core/lib/general.service";

@Component({
  selector: 'app-work-order-import-file-configuration-dialog',
  templateUrl: './work-order-import-file-configuration-dialog.component.html'
})
export class WorkOrderImportFileConfigurationDialogComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  finalizeButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Salva")
    .setAction(() => this.onImport())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  form: FormGroup;
  configurationLookup: CtControlConfiguration = this.generalService
    .getLookupControlDefaultConfiguration({
      name: 'Configuration',
      controller: 'FileImportConfiguration',
      type: CtControlTypes.ENUMERABLE
    })
    .setTheme(CtThemeTypes.MATERIAL)
    .setLabel('Configurazione')

  constructor(
    private dialogRef: MatLegacyDialogRef<WorkOrderImportFileConfigurationDialogComponent>,
    private formBuilder: FormBuilder,
    private generalService: CTMGeneralService
  ) {

    this.form = this.formBuilder.group({})

    this.configurationLookup
      .setFormParent(this.form);

  }

  onCancel() {

    this.dialogRef.close();

  }

  onImport() {

    if (this.form.invalid) return;

    this.dialogRef
      .close(this.configurationLookup.control?.value)

  }

}
