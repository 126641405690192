import {Injectable} from "@angular/core";
import {CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Handling} from "../classes/handling";
import {ItemRouteData} from "../route-data/item.route-data";
import {ModuleCreationRequestParameter} from "../classes/module-creation-request-parameter";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([ItemRouteData().controller!]);

@Injectable()
export class ItemController {

  constructor(private _webapi: CtWebapiService) {
  }

  moduleCreationRequest(parameter: ModuleCreationRequestParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ModuleCreationRequest")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(parameter);

    return this._webapi
      .request(request);

  }

}

export class ModuleCreationRequestResult {

  RequestFullFilled: boolean | null = null;
  Errors: any[] | null = null;
  ModuleQty: number | null = null;
  Handlings: Handling[] | null = null;

}


