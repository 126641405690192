import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadWorkOrderListComponent} from './lead-work-order-list.component';
import {CtFrameworkModule, CtModelModule} from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    CtFrameworkModule
  ],
  declarations: [LeadWorkOrderListComponent],
  exports: [LeadWorkOrderListComponent]
})
export class LeadWorkOrderListModule {
}
