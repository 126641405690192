<div class="work-order-edit-container p-10">

  <mat-tab-group [(selectedIndex)]="viewModel.selectedIndex">

    <mat-tab label="Dati generali">

      <ctm-general-data
        #generalDataComponent
        *ngIf="generalDataConfiguration"
        [configuration]="generalDataConfiguration"
        (onSubmit)="submit($event)"></ctm-general-data>

    </mat-tab>

    <ng-container *ngIf="viewModel.workOrderRowHandlingEnabled">

      <mat-tab
        [label]="isQuotation ? 'Righe preventivo' : 'Righe commessa'">

        <ctm-movement-manager
          #movementManagerComponent
          [configuration]="movementManagerConfiguration"></ctm-movement-manager>

      </mat-tab>

      <mat-tab
        [label]="'Movimentazioni/Ordini'"
        *ngIf="!isQuotation">

        <ctm-work-order-handlings
          [configuration]="workOrderHandlingsConfiguration"></ctm-work-order-handlings>

      </mat-tab>

    </ng-container>

  </mat-tab-group>

</div>


