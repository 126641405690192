<app-lead-edit-form (onSubmit)="submit($event)" [masterDetail]="enableMasterDetail" [datatableContainerFilterValues]="datatableContainerFilterValues"></app-lead-edit-form>

<ng-container *ngIf="enableMasterDetail">

  <div class="ct-manager-master-detail-container">

    <app-lead-work-order-list [masterConfiguration]="leadModelConfiguration"></app-lead-work-order-list>

  </div>

  <app-lead-place-list [masterConfiguration]="leadModelConfiguration"></app-lead-place-list>

  <div class="ct-manager-master-detail-container">

    <app-lead-dynamic-form [leadModelConfiguration]="leadModelConfiguration" [datatableContainerFilterValues]="datatableContainerFilterValues"></app-lead-dynamic-form>

  </div>

</ng-container>
