import {Component, Inject, ViewChild} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN, SnackbarService} from "@ctsolution/ct-framework";

import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {ModuleCreateFormComponent} from "./module-create-form/module-create-form.component";
import {
  ItemController,
  ModuleCreationRequestResult
} from "../../../../_core/controllers/item.controller";
import {ModuleCreationRequestParameter} from "../../../../_core/classes/module-creation-request-parameter";
import {TransferItemsRequestParameter} from "../../../../_core/classes/transfer-items-request-parameter";
import {Observable} from "rxjs";
import {WorkOrderController} from "../../../../_core/controllers/work-order.controller";

@Component({
  selector: "app-module-create",
  templateUrl: "./module-create.component.html",
  styleUrls: ["./module-create.component.scss"],
  providers: [ItemController, WorkOrderController]
})
export class ModuleCreateComponent {

  @ViewChild('moduleCreateForm') moduleCreateForm: ModuleCreateFormComponent | null = null;

  moduleCreationRequestResult: ModuleCreationRequestResult | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  previewButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateMovementsLabel")
    .setAction(() => this.onPreview())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  finalizeButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("confirm")
    .setAction(() => this.onPreview(true))
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  viewModel = {
    title: "moduleGenerationLabel"
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public parameter: ModuleCreationRequestParameter | TransferItemsRequestParameter,
    private itemController: ItemController,
    private workOrderController: WorkOrderController,
    private dialogRef: MatDialogRef<ModuleCreateComponent>,
    private _snackbar: SnackbarService) {

    this.setupModel();

  }


  setupModel() {

    if (this.parameter instanceof TransferItemsRequestParameter) {

      this.viewModel.title = "workOrderImportLabel";

    }

  }


  onCancel() {

    this.dialogRef.close();

  }

  onPreview(submit = false) {

    const form = this.moduleCreateForm?.form;

    if (!form) return;

    form?.markAllAsTouched();

    if (form.valid) {

      if (submit) this.parameter.setFinalize(true);

      let observable: Observable<any> | null = null;

      const locationOid = form.value.LocationToOid.value;

      if (this.parameter instanceof TransferItemsRequestParameter) {

        this.parameter
          .setLocationOid(locationOid);

        observable = this.workOrderController
          .transferItemsToLocation(this.parameter);

      } else {

        (<ModuleCreationRequestParameter>this.parameter)
          .setLocationToOid(locationOid)
          .setQty(form.value.qty)

        observable = this.itemController
          .moduleCreationRequest(this.parameter);

      }

      observable
        ?.subscribe((response: CtWebapiGenericResponse<ModuleCreationRequestResult>) => {

          this.moduleCreationRequestResult = response.Result;

          if (submit) {

            this._snackbar.open("CT_MESSAGES.success_operation");

            this.dialogRef
              .close(response.Result)

          }

        });

    }

  }

}
