import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadInformationComponent } from './lead-information.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";


@NgModule({
  imports: [
    CommonModule,
    MatCardModule,

  ],
  declarations: [LeadInformationComponent],
  exports : [LeadInformationComponent]
})
export class LeadInformationModule { }
