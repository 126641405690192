<mat-card>

  <mat-card-content>

    <ct-form [configuration]="configuration"></ct-form>

    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between center">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
