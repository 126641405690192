import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadListComponent } from './lead-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [LeadListComponent],
  exports : [LeadListComponent]
})
export class LeadListModule { }
