import { Component, Input, OnInit } from '@angular/core';
import { CtButtonConfiguration, MAT_RAISED_ACCENT } from '@ctsolution/ct-framework';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { MovementManagerConfiguration } from '../movement-manager.configuration';
import { Router } from '@angular/router';

@Component({
  selector: 'app-movement-manager-lead',
  templateUrl: './movement-manager-lead.component.html',
  styleUrls: ['./movement-manager-lead.component.scss']
})
export class MovementManagerLeadComponent implements OnInit {


  @Input() configuration: MovementManagerConfiguration | null = null;

  leadOid : number | null = null;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("Vai al lead")
  .setAction(() => this.submit())
  .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(private generalService : CTMGeneralService, private router : Router) { }

  ngOnInit() {

   this.generalService.getInfos({controller : 'WorkOrder', oid : this.configuration?.WorkOrder?.Oid}).subscribe(result => {

    if(result){

      this.leadOid = result.Result.DataSource.Lead?.Oid

    }


   })

  }

  submit(){

    this.router.navigate([ '/' , 'lead' , 'edit' , this.leadOid ]);

  }

}
