<div class="work-order-import-file-configuration-dialog-container">

  <h1 mat-dialog-title> {{ 'Selezione configurazione' | translate }} </h1>

  <div mat-dialog-content style="min-height: 165px">

    <p> {{ 'Prima di proseguire con l\'import del file, devi selezionare la configurazione:' | translate }}</p>

    <form [formGroup]="form">

      <ct-control [configuration]="configurationLookup"></ct-control>

    </form>

  </div>

</div>

<div mat-dialog-actions
     fxLayout="row wrap"
     fxLayoutAlign="space-between center">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="finalizeButton"></ct-button>

</div>
