<mat-card>

  <mat-card-header
    fxLayout="row wrap"
    fxLayoutAlign="space-between center">

    <mat-card-title>{{ (configuration?.IsQuotation ? 'Dettagli preventivo' : 'Dettagli commessa') | translate }}</mat-card-title>

    <div>

      <ct-button
        *ngIf="hasHandlingManagementButtonEnabled"
        [configuration]="createButton"></ct-button>

    </div>

  </mat-card-header>

  <mat-card-content>

    <p-table
      #table
      class="movement-manager-rows-table"
      [value]="items">

      <ng-template pTemplate="header">

        <tr>
          <th style="width: 200px"> {{ 'ItemCode' | translate }}</th>
          <th> {{ 'ItemDescription' | translate }}</th>
          <th style="text-align: center; width: 80px"> {{ 'Quantity' | translate }}</th>
          <th style="text-align: center; width: 100px"> {{ 'Amount' | translate }}</th>
          <th style="text-align: center; width: 100px"> {{ 'Discount' | translate }}</th>
          <th style="text-align: center; width: 100px"> {{ 'IVA' | translate }}</th>
          <th style="text-align: center; width: 80px"></th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-item let-ri="rowIndex">

        <tr>

          <td>
            {{ item.Code }}

          </td>

          <td>

            {{ item.Description }}

          </td>

          <td style="text-align: center">

            {{ item.Quantity }}

          </td>

          <td style="text-align: center">

            {{ (item.Amount ?? 0) | currency:'EUR':true }}

          </td>

          <td style="text-align: center">

            {{ item.Discount ? (item.Discount + '%') : '-' }}

          </td>

          <td style="text-align: center">

            {{ item.VAT ? (item.VAT + '%') : '-' }}

          </td>

          <td>

            <div style="display: flex">

              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-pencil"
                (click)="onRowEdit(ri, item)" class="p-button-rounded p-button-text"></button>

              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-trash"
                *ngIf="item.State !== WorkOrderRowState.Closed"
                (click)="onRowDelete(ri, item)" class="p-button-rounded p-button-text"></button>

            </div>

          </td>

        </tr>

      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>

        <p> {{ 'CT_MESSAGES.no_available_data' | translate }}</p>
      </ng-template>

    </p-table>

  </mat-card-content>

</mat-card>
