import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovementManagerLeadComponent } from './movement-manager-lead.component';
import { CtButtonModule } from '@ctsolution/ct-framework';
import {MatLegacyCardModule} from "@angular/material/legacy-card";


@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatLegacyCardModule
  ],
  declarations: [MovementManagerLeadComponent],
  exports : [MovementManagerLeadComponent]
})
export class MovementManagerLeadModule { }
