 <mat-card>
  <mat-card-title style="padding-left: 34px; padding-top: 24px" *ngIf="title">Codice : {{title}}</mat-card-title>
  <mat-card-content>

    <ct-form [configuration]="configuration"></ct-form>

    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between center">

      <ct-button [configuration]="cancelButton"></ct-button>

      <app-lead-create-workorder *ngIf="masterDetail && leadStatusComplete"></app-lead-create-workorder>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>

<app-lead-information></app-lead-information>
