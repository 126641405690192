import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CtFormConfiguration, CtButtonConfiguration, MAT_RAISED_WARN, MAT_RAISED_ACCENT, CtControlTypes, CtThemeTypes, CTGeneralService, CtControlConfiguration, CtControlService, CTModelDatatableFilter, CtBinaryOperator, CtModelDatatableOperators, CtSelectControlOptions, CtSortOrderDescriptorParameter, CtChipsControlOptions, CtModelRouteData, CtModelConfiguration, CtModelService, CtDatatableContainerFilterValues, CtSelectControlValue } from '@ctsolution/ct-framework';
import { CtWebapiGenericResponse, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { LeadInformationComponent } from '../lead-information/lead-information.component';


@Component({
  selector: 'app-lead-edit-form',
  templateUrl: './lead-edit-form.component.html',
  styleUrls: ['./lead-edit-form.component.scss']
})
export class LeadEditFormComponent implements AfterViewInit {

  @Output() onSubmit: EventEmitter<FormGroup | null> = new EventEmitter<FormGroup | null>();
  @Input() masterDetail = false;
  @Input() datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  @ViewChild(LeadInformationComponent) leadInformationComponent!: LeadInformationComponent;


  companyOid: number | null = null;
  configuration: CtFormConfiguration;
  leadStatusComplete = false;
  title: string | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onSubmit.emit(this.configuration.form))
    .setMatherialOptions(MAT_RAISED_ACCENT);

  private nameControl = this.generalService.getTextControl({
    name: 'Name',
    required: true,
  });

  private surnameControl = this.generalService.getTextControl({
    name: 'Surname',
    required: true,
  });

  private phoneControl = this.generalService.getTextControl({
    name: 'Phone',
    required: false,
  })

  private emailControl = this.generalService.getTextControl({
    name: 'Email',
    required: false,
  });

  private noteControl = this.generalService.getTextControl({
    name: 'Note',
    required: false,
  }).setFlexSpacePercentage(100);

  private codeControl = this.generalService.getTextControl({
    name: 'Code',
    required: false,
  }).setFlexSpacePercentage(50).setHidden(true);

  private documentDateControl = this.generalService.getDateControlDefault({
    name: 'DocumentDate',
    required: true,
  }).setFlexSpacePercentage(50);

  private VatControl = this.generalService.getTextControl({
    name: 'VATNUMBER',
    required: false,
  }).setFlexSpacePercentage(50);

  private SDIControl = this.generalService.getTextControl({
    name: 'SDICODE',
    required: false,
  }).setFlexSpacePercentage(100);

  private leadTypeControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadType',
    controller: 'LeadType',
    type: CtControlTypes.ENUMERABLE
  }).setFlexSpacePercentage(50)

  private leadSourceControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadSource',
    controller: 'LeadSource',
    type: CtControlTypes.ENUMERABLE,
    required: false
  }).setFlexSpacePercentage(33.33);

  private leadStatusControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadStatus',
    controller: 'LeadStatus',
    type: CtControlTypes.ENUMERABLE
  }).setFlexSpacePercentage(33.33);

  private leadInterestControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadInterest',
    controller: 'LeadInterest',
    type: CtControlTypes.ENUMERABLE,
    required: false
  }).setFlexSpacePercentage(33.33);

  private customerControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'Customer',
    controller: 'Company',
    type: CtControlTypes.ENUMERABLE,
    required: false,
    action: 'LookupForCustomers',
  }).setNullValueEnabled(true)


  private customerTypeControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'CustomerType',
    controller: 'CompanyType',
    type: CtControlTypes.ENUMERABLE
  })
    .setLabel("Type")
    .setValue(CustomerTypeEnum.PRIVATE);

  private companyPlaceControl: CtControlConfiguration =
    this.controlService.getDefaultConfiguration({
      name: 'CompanyPlace',
      label: 'CompanyPlace',
      type: CtControlTypes.ENUMERABLE,
      remoteValueOptionsEndpoint: DataRequest
        .create()
        .setController(['CompanyPlace'])
        .setAction('Lookup')
        .setMethod(MethodEnum.POST)
        .setQueryParams({ hideSpinner: true })
    })
      .setTheme(CtThemeTypes.MATERIAL)
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          .setNullValueEnabled(false)
          .setLookupFilter((value) => {

            const filters: CTModelDatatableFilter[] = [
              CTModelDatatableFilter
                .create()
                .setField('Company.IsSelf')
                .setValue(true)
                .setOperatorType(CtBinaryOperator.Equal),
            ];

            if (value) {

              filters
                .push(
                  CTModelDatatableFilter.create().setField('Name').setValue(value).setOperatorType(CtBinaryOperator.Contains))
            }

            const operators = CtModelDatatableOperators
              .create()
              .setFilters(filters);

            operators
              .Pagination
              ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

            return operators;

          })

      })
      .setFlexSpacePercentage(50);


  private tagControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setName('Tags')
    .setLabel('TAG')
    .setType(CtControlTypes.CHIPS)
    .setTheme(CtThemeTypes.MATERIAL)
    .setRemoteValueOptionsEndpoint(
      DataRequest
        .create()
        .setUrl(`/Tag/Lookup`)
        .setMethod(MethodEnum.POST)
        .setBody(CtModelDatatableOperators.create()))
    .configureOptions<CtChipsControlOptions>(options => {

      if (!options) options = CtChipsControlOptions.create();

      options
        .setOnAdd((event: string) => new Promise<CtWebapiGenericResponse<number | string> | null>((resolve) => {

          const controller = 'tag';

          const routeData = CtModelRouteData
            .create()
            .setController(controller);

          const configuration = CtModelConfiguration
            .create()
            .setRouteData(routeData)

          this.modelService
            .putInfos(configuration, { content: event })
            ?.subscribe((response: CtWebapiGenericResponse<any>) => resolve(response.Result))

        }));


    }).setFlexSpacePercentage(50);

  private customerType: CustomerTypeEnum = CustomerTypeEnum.PRIVATE;
  private company: any = null;

  private controls = [
    this.customerControl,
    this.customerTypeControl,
    this.nameControl,
    this.surnameControl,
    this.phoneControl,
    this.emailControl,
    this.VatControl,
    this.SDIControl,
    this.documentDateControl,
    this.leadTypeControl,
    this.leadSourceControl,
    this.leadStatusControl,
    this.leadInterestControl,
    this.companyPlaceControl,
    this.tagControl,
    this.noteControl,
    this.codeControl
  ];



  constructor(
    private generalService: CTMGeneralService,
    private CTGeneralService: CTGeneralService,
    private formBuilder: FormBuilder,
    private controlService: CtControlService,
    private modelService: CtModelService<any>) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls.filter(control => control !== this.VatControl && control !== this.SDIControl));

  }

  ngAfterViewInit() {

    this.customerControl
      .control
      ?.valueChanges
      .subscribe(value => {

        if (value == null) {

          this.company = null;
          this.setController(this.company, this.customerType);
          this.companyOid = null;
          this.leadInformationComponent.reset();

          return

        }

        this.generalService.getInfos({ controller: "Company", oid: value }).subscribe((response: CtWebapiGenericResponse<any>) => {

          this.company = response.Result.DataSource;

          this.setController(this.company)

          if (this.company) {

            this.companyOid = this.company.Oid;
            this.leadInformationComponent.setup(this.companyOid);

          }

        })

      })

    this.customerTypeControl.control?.valueChanges.subscribe(value => {

      this.customerType = value;
      this.setController(this.company, this.customerType);

    })

    const codeLeadType = this.getValueFilterCodeLead();

    if (codeLeadType) {
      this.setupLeadTypeControlByQueryString(codeLeadType);
    }



  }

  setupLeadTypeControlByQueryString(code: string) {

    this.leadTypeControl.configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setLookupFilter(() => {

          const operators = CtModelDatatableOperators
            .create()

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('Code')
              .setValue(code)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          operators
            .setFilters(filters);

          return operators

        })
        ?.setLookupResponseMapper(
          (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            const result = (<Array<any>>response.Result.DataSource ?? []);

            if (result.length) {

              const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm));


              const firstOrDefault = toReturn[0];
              this.leadTypeControl?.setValue(firstOrDefault.value)

              return toReturn;

            }

            return [];
          })

    });

    (<CtSelectControlOptions>this.leadTypeControl.options).notifyValuesUpdated();
  }

  getValueFilterCodeLead(): string | null {

    let value: string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field === 'LeadType.Code')?.Value

    }

    return value

  }


  private setLeadCompanyControl() {

    if (!this.configuration.controls) return;

    this.customerControl.setFlexSpacePercentage(100);
    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls.filter(control => control !== this.nameControl && control !== this.surnameControl && control !== this.phoneControl && control !== this.emailControl && control !== this.VatControl && control !== this.SDIControl && control !== this.customerTypeControl));

    this.configuration.form?.updateValueAndValidity();

  }

  private unSetLeadCompanyControl(customerType: CustomerTypeEnum | null = null) {

    if (!this.configuration.controls) return;

    this.customerControl.setFlexSpacePercentage(50);

    if (CustomerTypeEnum.PRIVATE == customerType) {

      this.nameControl.setLabel('Name')
      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.VatControl && control !== this.SDIControl));

    }

    else {

      this.nameControl.setLabel('CompanyName')
      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.surnameControl));

    }

    this.configuration.form?.updateValueAndValidity();


  }

  private setController(company: any = null, customerType: CustomerTypeEnum | null = null) {


    if (company) {

      this.setLeadCompanyControl();

    }
    else {

      this.unSetLeadCompanyControl(customerType);

    }

  }


  setup<T>(value: T | null) {

    if (!value) return;

    const form: any = value;

    const formValue: any = {
      Customer: form.Customer?.Oid ?? null,
      CustomerType: form.CustomerType?.Oid ?? null,
      DocumentDate: form.DocumentDate,
      Email: form.Email ?? null,
      LeadInterest: form.LeadInterest?.Oid,
      LeadSource: form.LeadSource?.Oid,
      LeadStatus: form.LeadStatus.Oid,
      Name: form.Name ?? null,
      Phone: form.Phone ?? null,
      Surname: form.Surname ?? null,
      CompanyPlace: form.CompanyPlace?.Oid,
      Code: form.Code ?? null,
    }

    this.configuration.form?.patchValue(formValue);

    this.leadStatusComplete = formValue.LeadStatus == 4;

    this.title = this.configuration.form?.get('Code')?.value;

    const leadTypeCode = this.getValueFilterCodeLead();
    if (!leadTypeCode) this.leadTypeControl.setValue(form.LeadType.Oid ?? null);

    if (form.Tags?.length) {

      const tagsValue: CtSelectControlValue[] = [];

      form.Tags.forEach((tag: { Content: string | null; Oid: any; }) => {

        tagsValue.push(CtSelectControlValue.create().setLabel(tag.Content).setValue(tag.Oid))
      });

      setTimeout(() => {
        (<CtChipsControlOptions>this.tagControl.options)?.notifyValuesUpdated(tagsValue);

      }, 1000);

    }

  }


}

export enum CustomerTypeEnum {

  BUSINESS = 1,
  PRIVATE = 2

}
