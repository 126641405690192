export class TransferItemsRequestParameter {

  WorkOrderOid: number | null = null;
  LocationOid: number | null = null;
  Finalize: boolean = false;

  private constructor() {
  }

  public static create = (): TransferItemsRequestParameter => new TransferItemsRequestParameter();

  setWorkOrderOid(value: number): TransferItemsRequestParameter {

    this.WorkOrderOid = value;
    return this;

  }

  setLocationOid(value: number) {

    this.LocationOid = value;
    return this;

  }

  setFinalize(value: boolean) {

    this.Finalize = value;
    return this;

  }


}
