import {AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild} from "@angular/core";
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelType,
  MAT_RAISED_PRIMARY
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {CatalogService} from "../catalog.service";
import {CTManagerItem} from "../../../_core/classes/ct-manager-item";
import {ItemType} from "../../../_core/enum/item-type";
import {ItemSupplier} from "../../../_core/classes/item-supplier";
import {ItemSupplierRouteData} from "../../../_core/route-data/item-supplier.route-data";
import {ItemRouteData} from "../../../_core/route-data/item.route-data";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CatalogCloneItemComponent } from "./catalog-clone-item/catalog-clone-item.component";
import { CatalogCloneItemConfiguration } from "./catalog-clone-item/catalog-clone-item.configuration";

@Component({
  selector: "app-catalog-edit",
  template: `
    <ct-model *ngIf="itemModelConfiguration" [configuration]="itemModelConfiguration"></ct-model>

    <ng-container *ngIf="enableSupplierTable">

      <div class="ct-manager-master-detail-container">

        <h3> {{ 'supplierManagementLabel' | translate }} </h3>

        <ct-model *ngIf="suppliersModelConfiguration" [configuration]="suppliersModelConfiguration"></ct-model>

      </div>

    </ng-container>
    <ng-template #headerActions>

    <ct-button *ngIf="pId" [configuration]="createButton"></ct-button>

</ng-template>`
})
export class CatalogEditComponent implements AfterViewInit {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  itemModelRouteData: CtModelRouteData = ItemRouteData()
    .setModelType(CtModelType.FORM);
  itemModelConfiguration: CtModelConfiguration<CTManagerItem> | null = null;

  supplierModelRouteData: CtModelRouteData = ItemSupplierRouteData()
    .setModelType(CtModelType.DATATABLE);
  suppliersModelConfiguration: CtModelConfiguration<CatalogEditComponent> | null = null;

  pId: string | null = null;

  createButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("duplicateItem")
  .setIcon("content_copy")
  .setAction(() => this.createModule())
  .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private onFlyCreateHelper: CtModelOnFlyCreateService,
    private cdr: ChangeDetectorRef,
    private dialog : MatLegacyDialog
  ) {

    this.setupItemModelConfiguration()

    this.setupSuppliersModelConfiguration();

  }

  get enableSupplierTable(): boolean {

    if (!this.itemModelConfiguration) return false;

    const typeFilter = this.itemModelConfiguration.filterValues.constants?.find(flt => flt.Field === 'Type');

    return !!this.itemModelConfiguration.RouteData?.id && typeFilter?.Value === ItemType.Item;

  }

  setupItemModelConfiguration() {

    this.pId = this.route.snapshot.paramMap.get("id");

    if (this.pId) this.itemModelRouteData.setId(+this.pId);

    const configuration = CtModelConfiguration
      .create<CTManagerItem>()
      .setRouteData(this.itemModelRouteData);

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    if (constantType) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(constantType)
            .setOperatorType(CtBinaryOperator.Equal)
        );

      const isModuleTemplate: boolean = constantType === ItemType.Module;

      const fieldsCustomTemplates = [
        {prop: "Type", disabled: true, required: true, visible: false},
        {prop: "Suppliers", visible: false}, // useremo la tabella esterna per i fornitori
        {prop: "Category", visible: !isModuleTemplate},
        {prop: "Brand", visible: !isModuleTemplate},
        {
          prop: "DefaultUnitOfMeasure",
          disabled: !!configuration.RouteData?.id // per un articolo non devo piu poter modificare l'unità di misura una volta settato
        }
      ];

      if (isModuleTemplate) {

        this
          .route
          .data
          .subscribe(data => {
            data["title"] = "Assemblati";
          });

      }

      configuration
        .setFieldsCustomTemplates(fieldsCustomTemplates);

    }

    this.itemModelConfiguration = configuration;

  }

  setupSuppliersModelConfiguration() {

    const configuration = CtModelConfiguration
      .create<CatalogEditComponent>()
      .setRouteData(this.supplierModelRouteData)
      .setFilterable(false)
      .setFieldsCustomTemplates([{prop: "Item", disabled: false}])
      .setOnCreate(() => this.openItemSupplierForm())
      .setOnEdit((itemSupplier: ItemSupplier) => this.openItemSupplierForm(itemSupplier.Oid));

    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Item.Oid')
        .setValue(this.itemModelConfiguration?.RouteData?.id)
        .setOperatorType(CtBinaryOperator.Equal));

    this.suppliersModelConfiguration = configuration;


  }

  openItemSupplierForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(ItemSupplierRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.itemModelConfiguration?.DataSource) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('Item')
            .setValue(this.itemModelConfiguration?.DataSource)
            .setOperatorType(CtBinaryOperator.Equal)
        )

    }

    configuration
      .setRouteData(routeData)
      .setFieldsCustomTemplates([{prop: 'Item', disabled: true}])

    this.onFlyCreateHelper
      .openOnFlyCreate(configuration)
      .afterClosed()
      .subscribe((reload: any) => {

        if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });

  }

  ngAfterViewInit(): void {

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");
    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    //TODO: Centralizzare

    if(constantType == ItemType.Item){
      this.route
        .queryParams
        .subscribe(qp => {

          this.route
            .data
            .subscribe(data => {

              data['headerActions'] = this.headerActions;
              data['urls'] = [{title: ''}];
              this.cdr.detectChanges();


            })

        });
    }

  }

  createModule(){

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");
    const formValue = this.itemModelConfiguration?.form?.value;
    let codeValue : string | null = null;
    if(formValue){

      codeValue = formValue.Code;

    }


    if(!this.pId || !typePathValue || !codeValue) return;

    this.dialog.open(CatalogCloneItemComponent, {
      data: {
        configuration:
          CatalogCloneItemConfiguration.create()
            .setContent("cloneItemLabel")
            .setTitle("warning")
            .setItemOid(+this.pId)
            .setCodeValue(codeValue)
      }, maxWidth: '500px', minWidth: '300px'
    })


  }

}
