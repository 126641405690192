<mat-card>

  <mat-card-content>

    <ct-form *ngIf="formConfiguration" [configuration]="formConfiguration"></ct-form>

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div *ngIf="configuration?.WorkOrder?.Oid && configuration?.importEnabled">

        <input type="file" accept=".xlsx, .xls, .csv, .xml" #fileImport
               (change)="import($event)" onclick="this.value=null;" hidden/>

        <button
          (click)="fileImport.click()" mat-raised-button color="primary">
          <mat-icon>publish</mat-icon>
          Import
        </button>

      </div>

      <div>

        <ct-button [configuration]="submitButton"></ct-button>

        <ct-button
          class="m-l-5"
          *ngIf="isQuotation && configuration?.WorkOrder?.Oid"
          [configuration]="acceptQuotationButton"></ct-button>

      </div>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
