export class WorkOrderXmlImportParameter {

  File: File | null = null;
  WorkOrderOid: number | null = null;
  Code: string | null = null;

  private constructor() {
  }

  public static create = (): WorkOrderXmlImportParameter => new WorkOrderXmlImportParameter();

  setFile(value: File | null): WorkOrderXmlImportParameter {

    this.File = value;
    return this;

  }

  setWorkOrderOid(value: number | null): WorkOrderXmlImportParameter {

    this.WorkOrderOid = value;
    return this;

  }

  setCode(value: string | null): WorkOrderXmlImportParameter {

    this.Code = value;
    return this;

  }

}
