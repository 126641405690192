import { Injectable } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { CTMWorkOrderViewType } from "../../_core/enum/work-order-type";
import {
  CtBinaryOperator,
  CtControlService,
  CtControlTypes, CTDatatablePaginationParameter,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelService, CtModelType
} from "@ctsolution/ct-framework";
import { WorkOrderRowRouteData } from "../../_core/route-data/work-order-row.route-data";
import { WorkOrderInformationRouteData } from "../../_core/route-data/work-order-information.route-data";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";
import { CTWorkOrderRow } from "../../_core/classes/ct-work-order.row";
import { CTWorkOrderInformation } from "../../_core/classes/ct-work-order.information";
import { WorkOrderRowItemRouteData } from "../../_core/route-data/work-order-row-item.route-data";
import { WorkOrderRowOfferRouteData } from "../../_core/route-data/work-order-row-offer.route-data";
import { WorkOrderRowContactRouteData } from "../../_core/route-data/work-order-row-contact.route-data";
import { CtWorkOrderRowContact } from "../../_core/classes/ct-work-order.row-contact";
import { CtWorkOrderRowOffer } from "../../_core/classes/ct-work-order.row-offer";
import { CtWorkOrderRowItem } from "../../_core/classes/ct-work-order.row-item";
import { CTWorkOrderRowUnionType } from "../../_core/classes/ct-work-order.row-union";
import { CompanyRouteData } from "../../_core/route-data/company.route-data";
import { CTMGeneralService } from '../../_core/lib/general.service';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {WorkOrderErrorsDialogComponent} from "./work-order-errors-dialog/work-order-errors-dialog.component";

const WorkOrderRowConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderRowRouteData());
const WorkOrderRowItemConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderRowItemRouteData());
const WorkOrderRowOfferConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderRowOfferRouteData());
const WorkOrderRowContactConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderRowContactRouteData());

const WorkOrderInformationConfig = () => CtModelConfiguration.create().setRouteData(WorkOrderInformationRouteData());
const WorkOrderOidFilter = (workOrderOid: number) => CTModelDatatableFilter.create().setField("WorkOrder.Oid").setValue(workOrderOid).setOperatorType(CtBinaryOperator.Equal);

@Injectable()
export class WorkOrderService {

  constructor(private CTModelService: CtModelService<any>, private onFlyCreateHelper: CtModelOnFlyCreateService, private controlService: CtControlService, private generalService: CTMGeneralService, private dialog: MatLegacyDialog) {
  }

  getTextControl(value: { name: string, required?: boolean | null; flexSpacePercentage?: number | null }) {

    return this.controlService
      .getDefaultConfiguration<string | null>({
        name: value.name,
        flexSpacePercentage: value.flexSpacePercentage ?? 50,
        type: CtControlTypes.TEXT,
        required: value.required ?? false
      })

  }

  getRouteWorkOrderType(route: ActivatedRoute): Promise<CTMWorkOrderViewType> {

    return new Promise<CTMWorkOrderViewType>((resolve) => {

      route
        .data
        .subscribe({
          next: routeData => resolve((<any>routeData).type ?? CTMWorkOrderViewType.Standard),
          error: err => resolve(CTMWorkOrderViewType.Standard)
        });

    })

  }

  getRoutePathController(type: CTMWorkOrderViewType): string[] {

    const path: string[] = ['work-order'];

    switch (type) {

      case CTMWorkOrderViewType.Quotation:
        path.push('quotations');
        break;

      case CTMWorkOrderViewType.Standard:
      default:
        break;

    }

    return path;

  }


  putInformation<T>(value: CTWorkOrderInformation<T>) {
    const config = WorkOrderInformationConfig();
    return this.generalService.putData(config, value);
  }

  private getModelConfigByRow(rowData: CTWorkOrderRowUnionType) {

    if ('Item' in rowData) {

      return WorkOrderRowItemConfig;

    } else if ('Offer' in rowData) {

      return WorkOrderRowOfferConfig;

    } else if ('Contact' in rowData) {

      return WorkOrderRowContactConfig;

    } else {

      return WorkOrderRowConfig;

    }

  }

  putRow(row: CTWorkOrderRowUnionType) {

    const config: CtModelConfiguration<any> = this.getModelConfigByRow(row)();
    return this.generalService.putData(config, row);

  }

  deleteRow(row: CTWorkOrderRowUnionType) {

    const config: CtModelConfiguration<any> = this.getModelConfigByRow(row)();
    return this.CTModelService.delete(config, row);

  }

  private getWorkOrderData(config: any, workOrderOid: number, transformation: (elm: any) => any): Promise<Array<any> | null> {

    return new Promise<any | null>(resolve => {
      if (!config.RouteData?.controller) {

        resolve(null);
        return

      }
      this.generalService.getList({
        controller: config.RouteData.controller,
        filters: [WorkOrderOidFilter(workOrderOid)],
        pagination: new CTDatatablePaginationParameter(1, 100)
      }).subscribe((response: CtWebapiGenericResponse<any>) => {

        const dataSource = (response.Result.DataSource ?? []) ?? null;
        const transformedDataSource = dataSource ? dataSource.map(transformation) : null;
        resolve(transformedDataSource);

      });

    });

  }

  getInformations<T>(workOrderOid: number) {
    const config = WorkOrderInformationConfig();
    return <Promise<Array<CTWorkOrderInformation<T>>>>this.getWorkOrderData(config, workOrderOid, elm => CTWorkOrderInformation.create(elm));
  }

  getRows(workOrderOid: number) {
    const config = WorkOrderRowConfig();
    return <Promise<Array<CTWorkOrderRow>>>this.getWorkOrderData(config, workOrderOid, elm => CTWorkOrderRow.create(elm));
  }

  getRowsItem(workOrderOid: number) {
    const config = WorkOrderRowItemConfig();
    return <Promise<Array<CtWorkOrderRowItem>>>this.getWorkOrderData(config, workOrderOid, elm => CtWorkOrderRowItem.create(elm));
  }

  getRowsOffer(workOrderOid: number) {
    const config = WorkOrderRowOfferConfig();
    return <Promise<Array<CtWorkOrderRowOffer>>>this.getWorkOrderData(config, workOrderOid, elm => CtWorkOrderRowOffer.create(elm));
  }

  getRowsContact(workOrderOid: number) {
    const config = WorkOrderRowContactConfig();
    return <Promise<Array<CtWorkOrderRowContact>>>this.getWorkOrderData(config, workOrderOid, elm => CtWorkOrderRowContact.create(elm));
  }

  createCustomer(): Promise<any | null> {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(CompanyRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create().setRouteData(routeData)

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('IsCustomer')
          .setValue(true)
          .setOperatorType(CtBinaryOperator.Equal))

    return new Promise<any | null>(resolve => {

      this.onFlyCreateHelper
        .openOnFlyCreate(configuration)
        .afterClosed()
        .subscribe((response: CtWebapiGenericResponse<number> | null) => {

          const result = { ...configuration.form?.value, Oid: response?.Result };
          resolve(result)

        });

    })

  }

  errorsDialog(errors: string[]) {

    this.dialog.open(WorkOrderErrorsDialogComponent, {data: errors})

  }

}
