import { AfterViewInit, Component } from '@angular/core';
import { CtModelConfiguration } from '@ctsolution/ct-framework';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { LeadRouteData } from '../../../_core/route-data/lead.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html'
})
export class LeadListComponent implements AfterViewInit {

  configuration: CtModelConfiguration<LeadListComponent> | null = null;

  constructor(private general: CTMGeneralService) {}

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    this.configuration = CtModelConfiguration
      .create<LeadListComponent>()
      .setRouteData(LeadRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["lead"], "create", { qpHandling: 'preserve' }))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["lead"], "edit", {
        value: event,
        configuration: this.configuration,
        qpHandling: 'preserve'
      }))

  }

}
