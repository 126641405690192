import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDataComponent} from "./general-data.component";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtFormModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {
  WorkOrderImportFileConfigurationDialogModule
} from "../../work-order-import-file-configuration-dialog/work-order-import-file-configuration-dialog.module";

@NgModule({
  declarations: [GeneralDataComponent],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    CtFormModule,
    CtButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyButtonModule,
    WorkOrderImportFileConfigurationDialogModule
  ],
  exports: [GeneralDataComponent]
})
export class GeneralDataModule {
}
