import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {
  CtButtonConfiguration,
  CtControlTypes,
  CtFormConfiguration,
  CtSelectControlValue,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {FormBuilder} from "@angular/forms";
import {MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {MovementManagerAddItemConfiguration} from "./movement-manager-add-item.configuration";
import {CtWorkOrderRowItem} from "../../../../../../../_core/classes/ct-work-order.row-item";
import {CTWorkOrderRow} from "../../../../../../../_core/classes/ct-work-order.row";
import {CtWorkOrderRowOffer} from "../../../../../../../_core/classes/ct-work-order.row-offer";
import {CtWorkOrderRowContact} from "../../../../../../../_core/classes/ct-work-order.row-contact";
import {CTWorkOrderRowUnionType} from "../../../../../../../_core/classes/ct-work-order.row-union";
import {CTMGeneralService} from 'projects/ct-manager/src/app/_core/lib/general.service';
import {WorkOrderService} from '../../../../../work-order.service';
import {WorkOrderRowState} from "../../../../../../../_core/enum/work-order-row-state";
import {WorkOrderRowOrigin} from "../../../../../../../_core/enum/work-order-row-origin";

@Component({
  selector: 'app-movement-manager-add-item',
  templateUrl: './movement-manager-add-item.component.html',
  styleUrls: ['./movement-manager-add-item.component.scss'],
  providers: [WorkOrderService]
})
export class MovementManagerAddItemComponent {

  formConfiguration: CtFormConfiguration | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  addButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setAction(() => this.onAdd())
    .setMatherialOptions(MAT_RAISED_WARN);

  private controls = [
    this.generalService.getTextControl({name: 'Code', required: true, flexSpacePercentage: 100}),
    this.generalService.getTextControl({name: 'Description', required: true})
      .setType(CtControlTypes.TEXTAREA)
      .setFlexSpacePercentage(100),
    this.generalService.getTextControl({
      name: 'Quantity', required: true,
      flexSpacePercentage: 25
    })
      .setValue(1)
      .setType(CtControlTypes.NUMBER),
    this.generalService.getTextControl({
      name: 'Amount',
      required: true,
      flexSpacePercentage: 25
    })
      .setType(CtControlTypes.NUMBER)
      .setLabel('Importo (€)'),
    this.generalService.getTextControl({
      name: 'Discount',
      required: false,
      flexSpacePercentage: 25
    }).setType(CtControlTypes.NUMBER)
      .setValue(0)
      .setLabel('Sconto (%)'),
    this.generalService.getTextControl({
      name: 'VAT',
      required: false,
      flexSpacePercentage: 25
    }).setType(CtControlTypes.ENUMERABLE)
      .setLabel('IVA (%)')
      .setValueOptions([
        CtSelectControlValue.create().setLabel("4").setValue(4),
        CtSelectControlValue.create().setLabel("10").setValue(10),
        CtSelectControlValue.create().setLabel("22").setValue(22),
      ])
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private generalService: CTMGeneralService,
    private workOrderService: WorkOrderService,
    private dialogRef: MatLegacyDialogRef<MovementManagerAddItemComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private config: MovementManagerAddItemConfiguration) {

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

    if (this.config.Values) {

      this.setup(this.config.Values)

    }

  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

  setup(value: CTWorkOrderRowUnionType | null) {

    if (!value) return;

    this.formConfiguration
      ?.form
      ?.patchValue(value);

    const item = (<CtWorkOrderRowItem>value).Item;

    if (item) {

      if ('Description' in item) {

        const description = this.formConfiguration?.form?.get('Description');
        description?.setValue(item?.Description);
        description?.disable();

      }

      if ('Code' in item) {

        const code = this.formConfiguration?.form?.get('Code');
        code?.setValue(item?.Code)
        code?.disable();

      }

      if (this.config.Values?.State === WorkOrderRowState.Closed) {

        const quantity = this.formConfiguration?.form?.get('Quantity');
        quantity?.disable();

      }

    }

  }

  onAdd() {

    if (!this.formConfiguration?.form?.valid) return;

    const value = this.formConfiguration?.form?.getRawValue();
    const values = this.config.Values;

    let row: CTWorkOrderRowUnionType;

    if ((<CtWorkOrderRowItem>values)?.Item) {

      row = CtWorkOrderRowItem.create({Item: (<CtWorkOrderRowItem>values)?.Item, ...value});

    } else if ((<CtWorkOrderRowOffer>values)?.Offer) {

      row = CtWorkOrderRowOffer.create({Offer: (<CtWorkOrderRowOffer>values)?.Offer, ...value});

    } else if ((<CtWorkOrderRowContact>values)?.Contact) {

      row = CtWorkOrderRowContact.create({Contact: (<CtWorkOrderRowContact>values)?.Contact, ...value});

    } else {

      row = CTWorkOrderRow.create(value);

    }

    row
      .setWorkOrderOid(this.config.WorkOrder?.Oid ?? -1)

    if (values?.Oid) {

      row.setOid(values.Oid);

    }

    row
      .setState(values?.State ?? WorkOrderRowState.Open)
      .setOrigin(values?.Origin ?? WorkOrderRowOrigin.Standard);

    this.workOrderService
      .putRow(row)
      ?.then((response) => {

        if (response.Result && !isNaN(response?.Result)) {

          row
            .setOid(response.Result)

        }

        this.dialogRef
          .close(row)

      })

  }

  onCancel() {

    this.dialogRef
      .close();

  }

}
